export function useUtils() {
    const snackbar = useSnackbarStore();
    const authStore = useAuthStore()
    const router = useRouter()

    const download = async (url: string, fileName: string = '') => {
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error('Network response was not ok');
            const blob = await response.blob();

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);

            // 使用URL对象解析文件名
            const urlObj = new URL(url);
            const defaultFileName = urlObj.pathname.split('/').pop() || 'download';

            link.download = fileName || defaultFileName;
            document.body.appendChild(link);
            link.click();

            // Clean up by revoking the object URL and removing the link element
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        } catch (error) {
            snackbar.showError('Failed to download file');
        }
    };
    
    const loadScript = (src: string): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.defer = true;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error(`Failed to load script ${src}`));
            document.head.appendChild(script);
        });
    }

    function loginByToken(token: string, redirectTo?: string) {
    if (!token) return
        authStore.login(token)
        if (authStore.isLogin && redirectTo) {
            router.push(redirectTo)
        }
    }

    return {
        download,
        loadScript,
        loginByToken
    };
}
